import React, {useState} from 'react';
import Project from "./project";
import classes from "./projects.module.sass";
import ProjectLoadItem from "../projectLoadItem";

const ProjectList = ({projects, countProjects, setCountProjects, loading}) => {

    const [loadingTest, setLoadingTest] = useState(true);

    // Change button name
    let moreName = '';
    if (loading) {
        moreName = 'loading...';
    } else if (countProjects >= projects.length) {
        moreName = "that's all";
    } else {
        moreName = 'load more';
    }


    function renderProjects() {
        if (loading) {
            return (
                <div className="loading-items">
                    {[...Array(countProjects)].map((item, key) => {
                        return <ProjectLoadItem key={key}/>
                    })}
                </div>
            )
        } else {
            return (
                <>
                    <div className={classes.projectsList}>

                        {projects.map((project, key) => {
                            return (
                                <Project
                                    key={key}
                                    projectData={project}
                                />
                            )
                        })}
                    </div>

                </>

            )
        }
    }

    return (
        <>
            {renderProjects()}
            {countProjects <= projects.length ?
                <div className='load-more-btn'>
                    <button
                        className={classes.btn}
                        onClick={() => setCountProjects(countProjects = countProjects + 4)}
                    >
                        load more
                    </button>
                </div>
                :
                <div className='load-more-btn'>
                    <button
                        className={classes.btn}
                    >
                        {moreName}
                    </button>
                </div>
            }
        </>
    );
};

export default ProjectList;