import React from "react"
import ContentLoader from "react-content-loader"
const ProjectLoadItem = () => (
    <ContentLoader
        speed={1.5}
        width={253}
        height={115}
        viewBox="0 0 253 115"
        backgroundColor={"var(--loading-bg)"}
        foregroundColor={"var(--loading-animation)"}
        title="loading..."
    >
        <rect x="0" y="43" rx="2" ry="2" width="223" height="10" />
        <rect x="0" y="84" rx="2" ry="2" width="233" height="10" />
        <rect x="0" y="64" rx="2" ry="2" width="243" height="10" />
        <rect x="0" y="104" rx="2" ry="2" width="253" height="10" />
        <rect x="0" y="0" rx="2" ry="2" width="115" height="10" />
        <rect x="218" y="0" rx="2" ry="2" width="35" height="10" />
    </ContentLoader>
)

export default ProjectLoadItem

