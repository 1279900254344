import React, {useEffect} from 'react';
import Page from "./page";
import Title from "../components/UI/Title/title";
import {useNavigate} from "react-router-dom";
import {useTitle} from "../hooks/pageTitle";

const ProjectPage = ({projectData}) => {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = projectData.title.rendered + ' - Vladimir Petrov';
    }, [])

    return (

        <Page>
            <section className='project-head'>
                <div className="container">
                    <div className="top-head">
                        <Title>
                            {projectData.title.rendered}
                        </Title>
                        {projectData.acf.link_project ?
                            <a
                                className='btn'
                                target='_blank'
                                href={projectData.acf.link_project}
                            >visit site</a>
                            :
                            ''
                        }
                    </div>
                    <p className='date'>
                        {projectData.acf.year}
                    </p>
                    <p className="desc-project">
                        {projectData.acf.desc}
                    </p>
                    <img
                        className='project-general-img'
                        src={projectData.yoast_head_json.og_image[0].url}
                        alt={projectData.title.rendered + ' - Vladimir Petrov'}
                    />
                </div>

            </section>

            <section className='gallery-block'>
                <div className="container">
                    <div className="gallery-project">
                        {projectData.acf.gallery ?
                            projectData.acf.gallery.map((img, key) => {
                                return (
                                    <div key={key}>
                                        <img
                                            src={img.img}
                                            alt={projectData.title.rendered + ' - Vladimir Petrov'}
                                        />
                                        <span
                                            className={'after-img blur_' + key}
                                        ></span>

                                    </div>

                                )
                            })
                            :
                            ''
                        }
                    </div>
                    <button
                        className='btn-back'
                        onClick={() => navigate(-1)}
                    >
                        back
                    </button>
                </div>
            </section>

        </Page>
    );
};

export default ProjectPage;