import React from 'react';
import classes from "./projects.module.sass";
import {Link} from "react-router-dom";

const Project = ({projectData}) => {

    return (
        <Link to={'projects/' + projectData.slug} className={classes.item}>
            <div className={classes.projectItem__head}>
                <div className={classes.title}>
                    {projectData.title.rendered}
                </div>
                <div className={classes.date}>
                    {projectData.acf.year}
                </div>
            </div>
            <p>
                {projectData.acf.desc}
            </p>
            <div className={classes.previewProject}>
                <img src={projectData.yoast_head_json.og_image[0].url} alt=""/>

            </div>
        </Link>
    );
};

export default Project;