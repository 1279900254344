import React from 'react';
import Page from "./page";
import Title from "../components/UI/Title/title";
import Blob from "../components/svg/Blob";
import {Link} from "react-router-dom";

const NotFound = () => {

    // document.title = 'Page not found - Vladimir Petrov';

    return (
        <Page>
            <section className='error-page'>
                <div className="container text-center">
                    <Blob
                        classes='blob-error'
                        animate={true}
                    />
                    <div className="error-content">
                        <Title>404</Title>
                        <p>page not found</p>
                        <Link to="/" className="home-link">back to home</Link>
                    </div>

                </div>
            </section>
        </Page>
    );
};

export default NotFound;