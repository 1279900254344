import React, {useEffect, useState} from 'react';
import {Transition} from "react-transition-group";
import Alert from "../UI/alert/alert";
import * as emailjs from "emailjs-com";

const ContactForm = ({closeModal}) => {

    const SERVICE_ID = "service_2iap3vr";
    const TEMPLATE_ID = "template_c4l69gh";
    const USER_ID = "0AQfbSdoawdxhSJd8";

    const [value, setValue] = useState({
        name: '', mail: '', message: ''
    })

    let [alert, setAlert] = useState({
        status: false, type: ''
    });

    const sendEmail = (e) => {
        e.preventDefault();

        if (value.mail) {
            emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
                .then((result) => {
                    // console.log(result.text);
                    successForm()
                }, (error) => {
                    console.log(error.text);
                });
        } else {
            errorForm()
        }
    };

    useEffect(() => {
        document.querySelector('.require-input').classList.remove('not-valid')
    }, [value.mail])

    const successForm = () => {
        setAlert({...alert, status: true, type: 'success'})
        setTimeout(() => {
            closeModal(false)
            setAlert({...alert, status: false})
        }, 2500)
        document.querySelector('.require-input').classList.remove('not-valid')
        setValue({name: '', mail: '', message: ''});
    }

    const errorForm = () => {
        document.querySelector('.require-input').classList.add('not-valid')
        // setAlert({...alert, status: true, type: 'error'})
    }

    return (
        <>

            <Alert
                alert={alert}
                setAlert={setAlert}
            />

            <form onSubmit={sendEmail} className="contact-form">

                <div className="form-left">
                    <input
                        type="text"
                        name="name"
                        placeholder="name..."
                        value={value.name}
                        onChange={event => setValue({...value, name: event.target.value})}
                    />
                    <input
                        className="require-input"
                        type="text"
                        name="email"
                        placeholder="email or telegram..."
                        value={value.mail}
                        onChange={event => setValue({...value, mail: event.target.value})}
                    />
                </div>
                <div className="form-right">
                    <textarea
                        name="message"
                        placeholder="message..."
                        value={value.message}
                        onChange={event => setValue({...value, message: event.target.value})}
                    />

                </div>

                <button className='btn'>
                    send
                </button>
            </form>


        </>
    );
};

export default ContactForm;