import React from 'react';
import Header from "../components/header/Header";
import Footer from "../components/footer/footer";

const Page = ({children}) => {
    return (
        <div className="page-wrapper">
            <Header/>
                <main>
                    {children}
                </main>
            <Footer/>
        </div>
    );
};

export default Page;