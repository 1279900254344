import React, {useRef, useState} from 'react';
import Page from "./page";
import * as emailjs from "emailjs-com";
import Alert from "../components/UI/alert/alert";
import {
    CSSTransition,
    Transition,
    TransitionGroup,
} from 'react-transition-group';
import ContactForm from "../components/contact-form/ContactForm";

const ConnectForm = () => {



    return (
        <Page>
            <div className="container text-center">
            </div>
        </Page>
    );
};

export default ConnectForm;