import React from 'react';

const Close = ({...props}) => {
    return (
        <div className="close-icon" {...props}>
            <span></span><span></span>
        </div>
    );
};

export default Close;