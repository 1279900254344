import React from 'react';
import Close from "../UI/close";
import {CSSTransition} from "react-transition-group";

const Modal = ({children, titleModal, setVisible, visible}) => {
    // if (visible) {
        return (
            <CSSTransition
                in={visible}
                timeout={200}
                classNames="modal-wrapper"
            >
                <div
                    className="modal-wrapper"
                    onClick={() => setVisible(false)}
                >
                    <div className="modal-bg"></div>
                    <div
                        className="modal"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Close
                            onClick={() => setVisible(false)}
                        />
                        <div className="title-modal">{titleModal}</div>
                        {children}
                    </div>
                </div>
            </CSSTransition>
        );
    // }
};

export default Modal;