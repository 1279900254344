import React from 'react';

const Blob = ({classes, animate}) => {
    return (
        <div className={classes + ' blob'}>
            {animate ?
                <svg viewBox="0 0 705 582" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="start" d="M647.771 422.541C576.105 507.875 485.771 559.541 376.771 577.541C267.771 595.541 165.605 552.875 70.2715 449.541C-25.0619 346.208 -23.3952 245.875 75.2715 148.541C173.938 51.2081 280.105 1.70806 393.771 0.0413931C507.438 -1.62527 596.105 47.0414 659.771 146.041C723.438 245.041 719.438 337.208 647.771 422.541Z" fill="url(#paint0_linear)">
                        <animate
                            repeatCount="indefinite"
                            attributeName="d"
                            dur="10s"
                            values="M647.771 422.541C576.105 507.875 485.771 559.541 376.771 577.541C267.771 595.541 165.605 552.875 70.2715 449.541C-25.0619 346.208 -23.3952 245.875 75.2715 148.541C173.938 51.2081 280.105 1.70806 393.771 0.0413931C507.438 -1.62527 596.105 47.0414 659.771 146.041C723.438 245.041 719.438 337.208 647.771 422.541Z;
                                M615.5 396.5C543.833 481.833 470 507.5 361 525.5C252 543.5 143.953 526.866 48.6195 423.533C-46.7138 320.199 13.8333 230.333 112.5 133C211.167 35.6666 270.224 2.69931 383.891 1.03264C497.558 -0.634026 574.224 34 637.891 133C701.558 232 687.167 311.167 615.5 396.5Z;
                                M647.771 422.541C576.105 507.875 485.771 559.541 376.771 577.541C267.771 595.541 165.605 552.875 70.2715 449.541C-25.0619 346.208 -23.3952 245.875 75.2715 148.541C173.938 51.2081 280.105 1.70806 393.771 0.0413931C507.438 -1.62527 596.105 47.0414 659.771 146.041C723.438 245.041 719.438 337.208 647.771 422.541Z
                                "
                            // values="M647.771 422.541C576.105 507.875 485.771 559.541 376.771 577.541C267.771 595.541 165.605 552.875 70.2715 449.541C-25.0619 346.208 -23.3952 245.875 75.2715 148.541C173.938 51.2081 280.105 1.70806 393.771 0.0413931C507.438 -1.62527 596.105 47.0414 659.771 146.041C723.438 245.041 719.438 337.208 647.771 422.541Z; M638.5 435C566.833 520.333 468.5 545 359.5 563C250.5 581 158.333 545.833 63 442.5C-32.3334 339.167 -13.6668 251.833 84.9999 154.5C183.667 57.1667 267.104 1.70802 380.771 0.0413541C494.438 -1.62531 562.333 64.5 626 163.5C689.667 262.5 710.167 349.667 638.5 435Z; M647.771 422.541C576.105 507.875 485.771 559.541 376.771 577.541C267.771 595.541 165.605 552.875 70.2715 449.541C-25.0619 346.208 -23.3952 245.875 75.2715 148.541C173.938 51.2081 280.105 1.70806 393.771 0.0413931C507.438 -1.62527 596.105 47.0414 659.771 146.041C723.438 245.041 719.438 337.208 647.771 422.541Z"
                        >
                        </animate>
                    </path>
                    <defs>
                        <linearGradient id="paint0_linear" x1="-58.7285" y1="144.041" x2="784.271" y2="512.541" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#EB5757"/>
                            <stop offset="1" stopColor="#57B6EB"/>
                        </linearGradient>
                    </defs>
                </svg>
                // <svg viewBox="0 0 705 582" fill="none" xmlns="http://www.w3.org/2000/svg">
                //     <path
                //         d="M647.881 422.98C576.214 508.313 485.881 559.98 376.881 577.98C267.881 595.98 165.714 553.313 70.3806 449.98C-24.9527 346.647 -23.2861 246.313 75.3806 148.98C174.047 51.6467 280.214 2.14665 393.881 0.479988C507.547 -1.18668 596.214 47.48 659.881 146.48C723.547 245.48 719.547 337.647 647.881 422.98Z"
                //         fill="url(#paint0_linear_1488_39)"/>
                //     <defs>
                //         <linearGradient id="paint0_linear_1488_39" x1="-58.6194" y1="144.48" x2="784.381" y2="512.98"
                //                         gradientUnits="userSpaceOnUse">
                //             <stop stopColor="#EB5757"/>
                //             <stop offset="1" stopColor="#57B6EB"/>
                //         </linearGradient>
                //     </defs>
                // </svg>
                :
                <svg viewBox="0 0 705 582" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M647.881 422.98C576.214 508.313 485.881 559.98 376.881 577.98C267.881 595.98 165.714 553.313 70.3806 449.98C-24.9527 346.647 -23.2861 246.313 75.3806 148.98C174.047 51.6467 280.214 2.14665 393.881 0.479988C507.547 -1.18668 596.214 47.48 659.881 146.48C723.547 245.48 719.547 337.647 647.881 422.98Z"
                        fill="url(#paint0_linear_1488_39)"/>
                    <defs>
                        <linearGradient id="paint0_linear_1488_39" x1="-58.6194" y1="144.48" x2="784.381" y2="512.98"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#EB5757"/>
                            <stop offset="1" stopColor="#57B6EB"/>
                        </linearGradient>
                    </defs>
                </svg>
            }
        </div>
    );
};

export default Blob;