import React from 'react';

const Title = ({children, ...props}) => {
    return (
        <h2 {...props} className='title-block'>
            {children}
        </h2>
    );
};

export default Title;