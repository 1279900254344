import React, {useState} from 'react';
import Title from "../UI/Title/title";
import classes from "./footer.module.sass";
import Blob from "../svg/Blob";
import Modal from "../modal/modal";
import ContactForm from "../contact-form/ContactForm";

const Footer = () => {

    const [visible, setVisible] = useState(false);

    const socList = [
        {title: 'telegram', link: 'https://t.me/freetoweb'},
        {title: 'skype', link: 'https://join.skype.com/invite/gMaSCbfxYraf'},
        // {title: 'mail', link: 'mailto: freeto2012@gmail.com'},
    ]

    const getLinks = socList.map((item, key) => {
        return <li key={key}><a target='_blank' href={item.link}>{item.title}</a></li>
    })

    const close = (status) => {
        setVisible(status)
    }

    return (
        <>
            <footer id={'contacts'} className={classes.footer}>
                <div className="container">
                    <Title>contacts</Title>
                    <ul className={classes.socialList}>
                        {getLinks}
                        <li>
                            <a
                                onClick={() => setVisible(true)}
                            >contact me</a>
                        </li>
                    </ul>
                    <Blob
                        classes='blobFooter'
                        animate={true}
                    />
                    <div className={classes.copyright}>
                        Vladimir Petrov © 2022
                    </div>
                </div>
            </footer>
            <Modal
                visible={visible}
                setVisible={setVisible}
                titleModal="contact me"
            >
                <ContactForm closeModal={close}/>
            </Modal>
        </>

    );
};

export default Footer;