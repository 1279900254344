import React, {useEffect, useMemo, useState} from 'react';
import Title from "../UI/Title/title";
import classes from "./projects.module.sass";
import Blob from "../svg/Blob";
import projectService from "../../API/getProjects";
import ProjectList from "./projectList";

const Projects = () => {

    const [countProjects, setCountProjects] = useState(8);
    const [isLoading, setIsLoading] = useState(false)
    const [projects, setProjects] = useState([]);

    async function fetchPosts() {
        setIsLoading(true);
        const projectsData = await projectService.getAll(countProjects);
        setProjects(projectsData)
        setIsLoading(false);
    }

    useEffect(() => {
        fetchPosts()
    }, [countProjects])

    return (
        <section className={'projects-block ' + classes.projectsBlock} id='projects'>
            <div className="container">
                <Title>projects</Title>
                <ProjectList
                    projects={projects}
                    loading={isLoading}
                    countProjects={countProjects}
                    setCountProjects={setCountProjects}
                />
                <Blob
                    animate={true}
                    classes='blobProjects'
                />
            </div>

        </section>
    );
};

export default Projects;