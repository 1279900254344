import React, {useState} from 'react';
import Cookies from "universal-cookie/es6";
import ThemeIcon from "../../svg/themeIcon";


const ThemeSwitcher = () => {


    let prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)"),
        bodySelector = document.querySelector('body'),
        theme = new Cookies();

    const [currentTheme, setCurrentTheme] = useState(theme.get('theme'));


    if (theme.get('theme')) {
        if (prefersDarkScheme.matches === true) {
            theme.set('theme', currentTheme, {
                sameSite: true,
                path: '/'
            })
        } else {
            theme.set('theme', currentTheme, {
                sameSite: true,
                path: '/'
            })
        }
    } else {
        if (prefersDarkScheme.matches === true) {
            theme.set('theme', 'dark', {
                sameSite: true,
                path: '/'
            })
        } else {
            theme.set('theme', 'light', {
                sameSite: true,
                path: '/'
            })
        }
    }


    bodySelector.className = '';
    bodySelector.classList.add(currentTheme)


    function themeSwitch() {

        setCurrentTheme(currentTheme === 'dark' ? 'light' : 'dark')

        theme.set('theme', currentTheme, {
            sameSite: true,
            path: '/'
        })
    }


    return (
        <button
            className="themeToggle"
            onClick={() => themeSwitch()}
        >
            <ThemeIcon
                theme={currentTheme}
            />
        </button>
    );
};

export default ThemeSwitcher;