import axios from "axios";

export default class projectService {
    static async getAll(countProjects) {
        try {
            const apiUrl = 'https://cms.freeto.ru/wp-json/wp/v2/projects/?per_page=' + countProjects;
            const response = await axios.get(apiUrl);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }
}
