import React, {useEffect, useState} from 'react';
import axios from "axios";
import {BrowserRouter, Routes, Route, Switch} from "react-router-dom";
import ProjectPage from "./pages/projectPage";
import Home from "./pages/home/Home";
import ScrollToTop from "./pages/ScrollToTop";
import NotFound from "./pages/NotFound";
import projectService from "./API/getProjects";
import {useHistory} from "react-router-dom";
import TestForm from "./pages/testForm";


function App() {

    const [projects, setProjects] = useState([]);

    async function fetchPosts() {
        const projectsData = await projectService.getAll(100);
        setProjects(projectsData)
    }

    useEffect(() => {
        fetchPosts()
    }, [])

    const routers = projects.map((project, key) => {
        return (
            <Route
                key={key}
                exact
                path={'projects/' + project.slug}
                element={<ProjectPage projectData={project}/>}
            ></Route>
        )
    })



    return (
        <>
            <BrowserRouter>
                <ScrollToTop>
                        <Routes>
                            <Route
                                exact
                                path='/'
                                element={<Home/>}
                            ></Route>
                            <Route
                                exact
                                path='/form'
                                element={<TestForm />}
                            ></Route>
                            {routers}
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>
                </ScrollToTop>
            </BrowserRouter>

        </>
    );
}

export default App;
