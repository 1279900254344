import React from 'react';
import classes from "./header.module.sass";
import ThemeSwitcher from "./themeSwitcher/themeSwitcher";
// import {Link} from "react-router-dom";
import {HashLink as Link} from "react-router-hash-link";
import {Redirect, useNavigate} from "react-router-dom";

const Header = () => {

    const navigate = useNavigate();

    const menuAnchors = [
        {id: 'projects', title: 'projects'},
        {id: 'contacts', title: 'contacts'},
    ]

    function toScroll(event) {

        event.preventDefault();



        let blockToScroll = event.target.getAttribute('href').substr(1)

        if(blockToScroll === 'projects') {
            navigate(`/`)
        }

        setTimeout(() => {
            document.querySelector('#' + blockToScroll).scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        }, 10)
    }


    return (
        <header>
            <div className="container">
                <div className='header-row'>
                    <Link to="/" className={classes.logo}>
                        Vladimir Petrov
                    </Link>
                    <nav>
                        <ul>
                            {menuAnchors.map((item, key) => {
                                return (
                                    <li key={key}>
                                        <a
                                            href={'#' + item.id}
                                            onClick={(event) => toScroll(event)}
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                        <ThemeSwitcher/>
                    </nav>
                </div>


            </div>
        </header>
    );
};

export default Header;