import React, {useEffect} from 'react';
import Page from "../page";
import Blob from "../../components/svg/Blob";
import Projects from "../../components/projects/projects";

const Home = () => {

    useEffect(() => {
        document.title = 'Vladimir Petrov - Frontend developer portfolio';
    }, [])

    return (
        <Page>
            <section className='teaser-block'>
                <div className="container">
                    <div className="teaser">
                        <Blob animate={true}/>
                        <div className="teaser-content">
                            <h1 className='title-block'>I'm Petrov Vladimir, </h1>
                            <h3>a front-end developer</h3>
                        </div>
                    </div>
                </div>
            </section>
            <Projects/>
        </Page>

    );
};

export default Home;