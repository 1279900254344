import React, {useEffect} from 'react';
import classes from "./alert.module.sass";
import Close from "../close";
import {CSSTransition} from "react-transition-group";


const Alert = ({alert, setAlert}) => {

    // console.log(alert)

    const alertClasses = [classes.alert];

    if (alert.type === 'error') {
        alertClasses.push('alert-error')
    }

    // useEffect(() => {
    //
    // }, [alert.type])

    // if (alert.status) {
        return (
            <CSSTransition
                in={alert.status}
                timeout={200}
                classNames="alert-transition"
            >
                <div className={'alert-item ' + alertClasses.join(' ')}>
                    {alert.type === 'success' ? 'Massage have been sent, I will contact you soon!' : 'Error sending message'}
                    <Close
                        onClick={() => setAlert({...alert, status: false})}
                    />
                </div>
            </CSSTransition>
        );
    // }

};

export default Alert;